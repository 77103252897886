

const Cars = [
    {
        id: 1,
        ImagenPortada: require("../Assets/images/Cars/Ford-EcoSport-SE-Azul-2018/1.png"),
        Imagen: [ require("../Assets/images/Cars/Ford-EcoSport-SE-Azul-2018/1.png"), require("../Assets/images/Cars/Ford-EcoSport-SE-Azul-2018/2.png"), require("../Assets/images/Cars/Ford-EcoSport-SE-Azul-2018/3.png"), require("../Assets/images/Cars/Ford-EcoSport-SE-Azul-2018/4.png"), require("../Assets/images/Cars/Ford-EcoSport-SE-Azul-2018/5.png"), require("../Assets/images/Cars/Ford-EcoSport-SE-Azul-2018/6.png"), require("../Assets/images/Cars/Ford-EcoSport-SE-Azul-2018/7.png"), require("../Assets/images/Cars/Ford-EcoSport-SE-Azul-2018/8.png"), require("../Assets/images/Cars/Ford-EcoSport-SE-Azul-2018/9.png"), require("../Assets/images/Cars/Ford-EcoSport-SE-Azul-2018/10.png"), ],
        Reservado: "No",
        Vendido: "No",
        Precio: "USD 16.900",
        Marca: "Ford",
        Modelo: "EcoSport SE",
        Ano: "2018",
        Color: "Azul",
        Radicada: "Prov. Bs. As.",
        Kilometros: "58.000",
        Version: "SE",
	    Traccion: "4x2",
        Transmision: "Automatica",
        Velocidades: "6v",
        Combustible: "Nafta",
        Litros: "1.5",
        Puertas: "5",
        Carroceria: "Rural",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "Si",
        Airbag: "Si",
        Abs: "Si",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "Si"
    },

    {
        id: 2,
        ImagenPortada: require("../Assets/images/Cars/Ford-Focus-III-S-Gris-2018/1.png"),
        Imagen: [require("../Assets/images/Cars/Ford-Focus-III-S-Gris-2018/1.png"), require("../Assets/images/Cars/Ford-Focus-III-S-Gris-2018/2.png"), require("../Assets/images/Cars/Ford-Focus-III-S-Gris-2018/3.png"), require("../Assets/images/Cars/Ford-Focus-III-S-Gris-2018/4.png"), require("../Assets/images/Cars/Ford-Focus-III-S-Gris-2018/5.png"), require("../Assets/images/Cars/Ford-Focus-III-S-Gris-2018/6.png"), require("../Assets/images/Cars/Ford-Focus-III-S-Gris-2018/7.png"), require("../Assets/images/Cars/Ford-Focus-III-S-Gris-2018/8.png"), require("../Assets/images/Cars/Ford-Focus-III-S-Gris-2018/9.png"), require("../Assets/images/Cars/Ford-Focus-III-S-Gris-2018/10.png"), ],
        Reservado: "Si",
        Vendido: "No",
        Precio: "$ 16.500.000",
        Marca: "Ford",
        Modelo: "Focus III S",
        Ano: "2018",
        Color: "Gris",
        Radicada: "CABA",
        Kilometros: "123.000",
        Version: "S",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.6",
        Puertas: "4",
        Carroceria: "Auto",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "Si",
        Airbag: "Si",
        Abs: "Si",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "Si"
    },

    {
        id: 3,
        ImagenPortada: require("../Assets/images/Cars/Ford-Ka-Viral-Gris-2013/0-Portada.png"),
        Imagen: [require("../Assets/images/Cars/Ford-Ka-Viral-Gris-2013/1.png"), require("../Assets/images/Cars/Ford-Ka-Viral-Gris-2013/2.png"), require("../Assets/images/Cars/Ford-Ka-Viral-Gris-2013/3.png"), require("../Assets/images/Cars/Ford-Ka-Viral-Gris-2013/4.png"), require("../Assets/images/Cars/Ford-Ka-Viral-Gris-2013/5.png"), require("../Assets/images/Cars/Ford-Ka-Viral-Gris-2013/6.png"), require("../Assets/images/Cars/Ford-Ka-Viral-Gris-2013/7.png"), require("../Assets/images/Cars/Ford-Ka-Viral-Gris-2013/8.png"), require("../Assets/images/Cars/Ford-Ka-Viral-Gris-2013/9.png"), require("../Assets/images/Cars/Ford-Ka-Viral-Gris-2013/10.png"), require("../Assets/images/Cars/Ford-Ka-Viral-Gris-2013/11.png"), ],
        Reservado: "No",
        Vendido: "No",
        Precio: "USD 8.900",
        Marca: "Ford",
        Modelo: "Ka Viral",
        Ano: "2013",
        Color: "Gris",
        Radicada: "CABA",
        Kilometros: "128.000",
        Version: "Viral",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.6",
        Puertas: "3",
        Carroceria: "Auto",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "No",
        Airbag: "Si",
        Abs: "No",
        Asientos: "4",
        Radio: "Si",
        Bluetooth: "No"
    },

    {
        id: 4,
        ImagenPortada: require("../Assets/images/Cars/Chevrolet-Spin-Activ-LTZ-Azul-2018/1.png"),
        Imagen: [require("../Assets/images/Cars/Chevrolet-Spin-Activ-LTZ-Azul-2018/1.png")],
        Reservado: "No",
        Vendido: "No",
        Precio: "$ 19.500.000",
        Marca: "Chevrolet",
        Modelo: "Spin Activ LTZ",
        Ano: "2018",
        Color: "Azul",
        Radicada: "Prov. Bs. As.",
        Kilometros: "70.000",
        Version: "1.8",
	    Traccion: "4x2",
        TransChemision: "Automática",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.8",
        Puertas: "5",
        Carroceria: "Rural",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "Si",
        Airbag: "Si",
        Abs: "Si",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "Si"
    },

    {
        id: 5,
        ImagenPortada: require("../Assets/images/Cars/Chevrolet-Captiva-LTZ-Gris-2014/1.png"),
        Imagen: [require("../Assets/images/Cars/Chevrolet-Captiva-LTZ-Gris-2014/1.png")],
        Reservado: "No",
        Vendido: "No",
        Precio: "USD 14.800",
        Marca: "Chevrolet",
        Modelo: "Captiva LTZ",
        Ano: "2014",
        Color: "Gris",
        Radicada: "Chubut",
        Kilometros: "140.000",
        Version: "LTZ",
	    Traccion: "4x4",
        Transmision: "Automática",
        Velocidades: "6v",
        Combustible: "Diesel",
        Litros: "2.2",
        Puertas: "5",
        Carroceria: "Suv",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "Si",
        Airbag: "Si",
        Abs: "Si",
        Asientos: "7",
        Radio: "Si",
        Bluetooth: "Si"
    },

    {
        id: 6,
        ImagenPortada: require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/1.png"),
        Imagen: [ require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/1.png"), require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/2.png"), require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/3.png"), require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/4.png"), require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/5.png"), require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/6.png"), require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/7.png"), require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/8.png"), require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/9.png"), require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/10.png"), require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/11.png"), require("../Assets/images/Cars/Citroen-Xsara-Picasso-Gris-2009/12.png"), ],
        Reservado: "Si",
        Vendido: "No",
        Precio: "USD 6.500",
        Marca: "Citroen",
        Modelo: "Xsara Picasso",
        Ano: "2009",
        Color: "Gris",
        Radicada: "Prov. Bs. As.",
        Kilometros: "170.000",
        Version: "1.6",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.6",
        Puertas: "5",
        Carroceria: "Rural",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "No",
        Airbag: "Si",
        Abs: "No",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "No"
    },
    {
        id: 7,
        ImagenPortada: require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/1.png"),
        Imagen: [ require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/1.png"), require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/2.png"), require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/3.png"), require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/4.png"), require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/5.png"), require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/6.png"), require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/7.png"), require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/8.png"), require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/9.png"), require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/10.png"), require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/11.png"), require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/12.png"), require("../Assets/images/Cars/Fiat-Strada-Trekking-Gris-2018/13.png"), ],
        Reservado: "No",
        Vendido: "No",
        Precio: "USD 12.900",
        Marca: "Fiat",
        Modelo: "Strada Trekking",
        Ano: "2018",
        Color: "Gris",
        Radicada: "Prov. Bs. As.",
        Kilometros: "138.000",
        Version: "Trekking",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Diesel",
        Litros: "1.3",
        Puertas: "3",
        Carroceria: "Pick Up",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "No",
        Airbag: "Si",
        Abs: "Si",
        Asientos: "4",
        Radio: "Si",
        Bluetooth: "Si"
    },
    {
        id: 8,
        ImagenPortada:  require("../Assets/images/Cars/Peugeot-208-Like-Blanco-2022/1.png"),
        Imagen: [ require("../Assets/images/Cars/Peugeot-208-Like-Blanco-2022/1.png"), require("../Assets/images/Cars/Peugeot-208-Like-Blanco-2022/2.png"), require("../Assets/images/Cars/Peugeot-208-Like-Blanco-2022/3.png"), require("../Assets/images/Cars/Peugeot-208-Like-Blanco-2022/4.png"), require("../Assets/images/Cars/Peugeot-208-Like-Blanco-2022/5.png"), require("../Assets/images/Cars/Peugeot-208-Like-Blanco-2022/6.png"), require("../Assets/images/Cars/Peugeot-208-Like-Blanco-2022/7.png"), require("../Assets/images/Cars/Peugeot-208-Like-Blanco-2022/8.png"), require("../Assets/images/Cars/Peugeot-208-Like-Blanco-2022/9.png"), require("../Assets/images/Cars/Peugeot-208-Like-Blanco-2022/10.png"), require("../Assets/images/Cars/Peugeot-208-Like-Blanco-2022/11.png"), ],
        Reservado: "No",
        Vendido: "No",
        Precio: "USD 16.500",
        Marca: "Peugeot",
        Modelo: "208 Like",
        Ano: "2022",
        Color: "Blanco",
        Radicada: "Prov. Bs. As.",
        Kilometros: "28.000",
        Version: "Like",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.2",
        Puertas: "5",
        Carroceria: "Auto",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "Si",
        Airbag: "Si",
        Abs: "Si",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "Si"
    },

    {
        id: 9,
        ImagenPortada: require("../Assets/images/Cars/Renault-Clio-Bordo-2003/1.png"),
        Imagen: [ require("../Assets/images/Cars/Renault-Clio-Bordo-2003/1.png"), ],
        Reservado: "Si",
        Vendido: "No",
        Precio: "USD 4.200",
        Marca: "Renault",
        Modelo: "Clio II Fase 2",
        Ano: "2003",
        Color: "Bordo",
        Radicada: "CABA",
        Kilometros: "148.000",
        Version: "",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.6",
        Puertas: "4",
        Carroceria: "Auto",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "No",
        Airbag: "Si",
        Abs: "No",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "No"
    },

    {
        id: 10,
        ImagenPortada: require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/1.png"), 
        Imagen: [ require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/1.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/2.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/3.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/4.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/5.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/6.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/7.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/8.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/9.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/10.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/11.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/12.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/13.png"), require("../Assets/images/Cars/Renault-Duster-Privilege-Bordo-2017/14.png"), ],
        Reservado: "No",
        Vendido: "No",
        Precio: "$ 19.500.000",
        Marca: "Renault",
        Modelo: "Duster Privilege",
        Ano: "2017",
        Color: "Bordo",
        Radicada: "CABA",
        Kilometros: "130.000",
        Version: "Privilege",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "6v",
        Combustible: "Nafta",
        Litros: "2.0",
        Puertas: "5",
        Carroceria: "Rural",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "Si",
        Airbag: "Si",
        Abs: "Si",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "Si"
    },

    {
        id: 11,
        ImagenPortada: require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/1.png"),
        Imagen: [require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/1.png"), require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/2.png"), require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/3.png"), require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/4.png"), require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/5.png"), require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/6.png"), require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/7.png"), require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/8.png"), require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/9.png"), require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/10.png"), require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/11.png"), require("../Assets/images/Cars/Renault-Kangoo-Confort-Blanco-2010/12.png"), ],
        Reservado: "No",
        Vendido: "Si",
        Precio: "USD 7.500",
        Marca: "Renault",
        Modelo: "Kangoo Confort",
        Ano: "2010",
        Color: "Blanco",
        Radicada: "CABA",
        Kilometros: "138.000",
        Version: "Confort",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.6",
        Puertas: "4",
        Carroceria: "Furgon",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "No",
        Airbag: "Si",
        Abs: "Si",
        Asientos: "2",
        Radio: "Si",
        Bluetooth: "No"
    },

    {
        id: 12,
        ImagenPortada: require("../Assets/images/Cars/Fiat-Punto-ELX-Gris-2008/1.png"),
        Imagen: [ require("../Assets/images/Cars/Fiat-Punto-ELX-Gris-2008/1.png"), require("../Assets/images/Cars/Fiat-Punto-ELX-Gris-2008/2.png"), require("../Assets/images/Cars/Fiat-Punto-ELX-Gris-2008/3.png"), require("../Assets/images/Cars/Fiat-Punto-ELX-Gris-2008/4.png"), require("../Assets/images/Cars/Fiat-Punto-ELX-Gris-2008/5.png"), require("../Assets/images/Cars/Fiat-Punto-ELX-Gris-2008/6.png"), require("../Assets/images/Cars/Fiat-Punto-ELX-Gris-2008/7.png"), require("../Assets/images/Cars/Fiat-Punto-ELX-Gris-2008/8.png"), require("../Assets/images/Cars/Fiat-Punto-ELX-Gris-2008/9.png"), ],
        Reservado: "Si",
        Vendido: "No",
        Precio: "$ 7.900.000",
        Marca: "Fiat",
        Modelo: "Punto ELX",
        Ano: "2008",
        Color: "Gris",
        Radicada: "Prov. Bs. As.",
        Kilometros: "140.000",
        Version: "ELX",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.4",
        Puertas: "5",
        Carroceria: "Auto",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "No",
        Airbag: "Si",
        Abs: "No",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "No"
    },

    {
        id: 13,
        ImagenPortada: require("../Assets/images/Cars/Chevrolet-Onix-LTZ-Bronce-2014/1.png"),
        Imagen: [require("../Assets/images/Cars/Chevrolet-Onix-LTZ-Bronce-2014/1.png")],
        Reservado: "No",
        Vendido: "Si",
        Precio: "USD 13.000",
        Marca: "Chevrolet",
        Modelo: "Onix LTZ",
        Ano: "2014",
        Color: "Bronce",
        Radicada: "CABA",
        Kilometros: "36.000",
        Version: "LTZ",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.4",
        Puertas: "5",
        Carroceria: "Auto",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "Si",
        Airbag: "Si",
        Abs: "Si",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "Si"
    },
    {
        id: 14,
        ImagenPortada: require("../Assets/images/Cars/Volkswagen-Gol-Power-Gris-2008/1.png"),
        Imagen: [require("../Assets/images/Cars/Volkswagen-Gol-Power-Gris-2008/1.png")],
        Reservado: "No",
        Vendido: "Si",
        Precio: "USD 6.500",
        Marca: "Volkswagen",
        Modelo: "Gol Power",
        Ano: "2008",
        Radicada: "CABA",
        Color: "Gris",
        Kilometros: "68.000",
        Version: "Power",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.6",
        Puertas: "3",
        Carroceria: "Auto",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "No",
        Airbag: "No",
        Abs: "No",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "No"
    },

    {
        id: 15,
        ImagenPortada: require("../Assets/images/Cars/Chery-QQ-Azul-2017/1.png"),
        Imagen: [require("../Assets/images/Cars/Chery-QQ-Azul-2017/1.png")],
        Reservado: "No",
        Vendido: "No",
        Precio: "USD 8.900",
        Marca: "Chery",
        Modelo: "QQ",
        Ano: "2018",
        Color: "Azul",
        Radicada: "Prov. Bs. As.",
        Kilometros: "76.000",
        Version: "Light",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.1",
        Puertas: "5",
        Carroceria: "Auto",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "Si",
        Airbag: "Si",
        Abs: "Si",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "Si"
    },

    {
        id: 16,
        ImagenPortada: require("../Assets/images/Cars/Chevrolet-Classic-Spirit-Gris-2012/1.png"),
        Imagen: [ require("../Assets/images/Cars/Chevrolet-Classic-Spirit-Gris-2012/1.png"), require("../Assets/images/Cars/Chevrolet-Classic-Spirit-Gris-2012/2.png"), require("../Assets/images/Cars/Chevrolet-Classic-Spirit-Gris-2012/3.png"), require("../Assets/images/Cars/Chevrolet-Classic-Spirit-Gris-2012/4.png"), require("../Assets/images/Cars/Chevrolet-Classic-Spirit-Gris-2012/5.png"), require("../Assets/images/Cars/Chevrolet-Classic-Spirit-Gris-2012/6.png"), require("../Assets/images/Cars/Chevrolet-Classic-Spirit-Gris-2012/7.png"), require("../Assets/images/Cars/Chevrolet-Classic-Spirit-Gris-2012/8.png"), require("../Assets/images/Cars/Chevrolet-Classic-Spirit-Gris-2012/9.png"), require("../Assets/images/Cars/Chevrolet-Classic-Spirit-Gris-2012/10.png"), ],
        Reservado: "No",
        Vendido: "Si",
        Precio: "USD 7.600",
        Marca: "Chevrolet",
        Modelo: "Classic Spirit",
        Ano: "2012",
        Color: "Gris",
        Radicada: "Prov. Bs. As.",
        Kilometros: "148.000",
        Version: "Spirit",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta / GNC",
        Litros: "1.4",
        Puertas: "4",
        Carroceria: "Auto",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "No",
        Airbag: "No",
        Abs: "No",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "No"
    },

    {
        id: 17,
        ImagenPortada: require("../Assets/images/Cars/Citroen-C3-Pack-ZE-Rojo-2013/1.png"),
        Imagen: [require("../Assets/images/Cars/Citroen-C3-Pack-ZE-Rojo-2013/1.png")],
        Reservado: "No",
        Vendido: "Si",
        Precio: "USD 8.900",
        Marca: "Citroen",
        Modelo: "C3 Pack ZE",
        Ano: "2013",
        Color: "Rojo",
        Radicada: "CABA",
        Kilometros: "96.000",
        Version: "ZE",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.4",
        Puertas: "5",
        Carroceria: "Auto",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "Si",
        Airbag: "Si",
        Abs: "Si",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "Si"
    },

    {
        id: 18,
        ImagenPortada: require("../Assets/images/Cars/Ford-Fiesta-Kinetic-Blanco-2011/1.png"),
        Imagen: [require("../Assets/images/Cars/Ford-Fiesta-Kinetic-Blanco-2011/1.png")],
        Reservado: "No",
        Vendido: "Si",
        Precio: "USD 9.900",
        Marca: "Ford",
        Modelo: "Fiesta Kinetic Tit.",
        Ano: "2011",
        Color: "Blanco",
        Radicada: "CABA",
        Kilometros: "113.000",
        Version: "Titanium",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.6",
        Puertas: "5",
        Carroceria: "Auto",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "Si",
        Airbag: "Si",
        Abs: "Si",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "Si"
    },

    // {
    //     id: 19,
    //     ImagenPortada: require("../Assets/images/Cars/focus-ghia-2012.png"),
    //     Imagen: [ require("../Assets/images/Cars/focus-ghia-2012.png") ],
    //     Reservado: "No",
    //     Vendido: "No",
    //     Precio: "USD 14.000",
    //     Marca: "Ford",
    //     Modelo: "Focus Ghia SP",
    //     Ano: "2012",
    //     Color: "Azul",
    //     Radicada: "Prov. Bs. As.",
    //     Kilometros: "",
    //     Version: "SP",
	//     Traccion: "4x2",
    //     Transmision: "Manual",
    //     Velocidades: "",
    //     Combustible: "",
    //     Litros: "",
    //     Puertas: "5",
    //     Carroceria: "",
    //     Direccion: "",
    //     Aire: "",
    //     SensorDistancia: "",
    //     Airbag: "",
    //     Abs: "",
    //     Asientos: "5",
    //     Radio: "",
    //     Bluetooth: ""
    // },
    {
        id: 20,
        ImagenPortada: require("../Assets/images/Cars/Peugeot-205-Verde-1997/1.png"),
        Imagen: [require("../Assets/images/Cars/Peugeot-205-Verde-1997/1.png")],
        Reservado: "No",
        Vendido: "Si",
        Precio: "$ 2.900.000",
        Marca: "Peugeot",
        Modelo: "205",
        Ano: "1997",
        Color: "Verde",
        Radicada: "CABA",
        Kilometros: "150.000",
        Version: "",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.4",
        Puertas: "3",
        Carroceria: "Auto",
        Direccion: "No",
        Aire: "No",
        SensorDistancia: "No",
        Airbag: "No",
        Abs: "No",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "No"
    },
    {
        id: 21,
        ImagenPortada: require("../Assets/images/Cars/Renault-Clio-Gris-2008/1.png"),
        Imagen: [require("../Assets/images/Cars/Renault-Clio-Gris-2008/1.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/2.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/3.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/4.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/5.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/6.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/7.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/8.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/9.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/10.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/11.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/12.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/13.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/14.png"), require("../Assets/images/Cars/Renault-Clio-Gris-2008/15.png"), ],
        Reservado: "No",
        Vendido: "Si",
        Precio: "USD 6.700",
        Marca: "Renault",
        Modelo: "Clio II Fase 3",
        Ano: "2008",
        Color: "Gris",
        Radicada: "CABA",
        Kilometros: "119.000",
        Version: "",
	    Traccion: "4x2",
        Transmision: "Manual",
        Velocidades: "5v",
        Combustible: "Nafta",
        Litros: "1.2",
        Puertas: "5",
        Carroceria: "Auto",
        Direccion: "Si",
        Aire: "Si",
        SensorDistancia: "No",
        Airbag: "No",
        Abs: "No",
        Asientos: "5",
        Radio: "Si",
        Bluetooth: "No"
    },

   
]

export default Cars;